const menuConfig = {
  defaultContentLanguage: 'de',
  metaDataFormat: 'yaml',
  languages: {
    de: {
      languageName: 'German',
      contentDir: 'content/de',
      menu: {
        main: [
          {
            name: 'Programme',
            url: '/programs',
            identifier: 'programs',
            children: [
              {
                name: 'Familienstellen',
                url: '/programs/familienstellen/',
                identifier: 'familienstellen',
              },
              {
                name: 'Meditation',
                url: '/programs/meditation/',
                identifier: 'meditation',
              },
              {
                name: 'Energetische Arbeit',
                url: '/programs/energetische-arbeit/',
                identifier: 'energetische-arbeit',
              },
            ],
          },
          {
            name: 'Seminare',
            url: '/seminars',
            identifier: 'seminars',
            children: [
              {
                name: 'Aufstellungsworkshops',
                url: '/seminars/aufstellungs-workshops',
                identifier: 'aufstellungs-workshops',
              },
              {
                name: 'Meditationsseminare',
                url: '/seminars/meditationsseminare',
                identifier: 'meditationsseminare',
              },
              {
                name: 'Systemische Seminare',
                url: '/seminars/systemische-seminare',
                identifier: 'systemische-seminare',
              },
            ],
          },
          {
            name: 'Termine',
            url: '/calendar',
            identifier: 'calendar',
          },
          {
            name: 'Über Mich',
            url: '/about',
            identifier: 'about',
          },
          {
            name: 'Kontakt',
            identifier: 'contact',
            url: '/contact',
          },
        ],
      },
    },
    ru: {
      languageName: 'Russian',
      contentDir: 'content/ru',
      menu: {
        main: [
          {
            name: 'программы',
            url: '/programs',
            identifier: 'programs',
            children: [
              {
                name: 'системные расстановки',
                url: '/programs/familienstellen/',
                identifier: 'familienstellen',
              },
              {
                name: 'Медитации',
                url: '/programs/meditation/',
                identifier: 'meditation',
              },
              {
                name: 'Энергетическая работа',
                url: '/programs/energetische-arbeit/',
                identifier: 'energetische-arbeit',
              },
            ],
          },
          {
            name: 'семинары',
            url: '/seminars',
            identifier: 'seminars',
            children: [
              {
                name: 'воркшопы',
                url: '/seminars/aufstellungs-workshops',
                identifier: 'aufstellungs-workshops',
              },
              {
                name: 'Медитативные семинары',
                url: '/seminars/meditationsseminare',
                identifier: 'meditationsseminare',
              },
              {
                name: 'Системные семинары',
                url: '/seminars/systemische-seminare',
                identifier: 'systemische-seminare',
              },
            ],
          },
          {
            name: 'календарь',
            url: '/calendar',
            identifier: 'calendar',
          },
          {
            name: 'обо мне',
            url: '/about',
            identifier: 'about',
          },
          {
            name: 'контакт',
            identifier: 'contact',
            url: '/contact',
          },
        ],
      },
    },
    en: {
      languageName: 'English',
      contentDir: 'content/en',
      menu: {
        main: [
          {
            name: 'Programs',
            url: '/programs',
            identifier: 'programs',
          },
          {
            name: 'Seminars',
            url: '/seminars',
            identifier: 'seminars',
            children: [
              {
                name: 'Aufstellungs Workshops',
                url: '/seminars/aufstellungs-workshops',
                identifier: 'aufstellungs-workshops',
              },
              {
                name: 'Meditationsseminare',
                url: '/seminars/meditationsseminare',
                identifier: 'meditationsseminare',
              },
              {
                name: 'Systemische Seminare',
                url: '/seminars/systemische-seminare',
                identifier: 'systemische-seminare',
              },
            ],
          },
          {
            name: 'Calendar',
            url: '/calendar',
            identifier: 'calendar',
          },
          {
            name: 'About',
            url: '/about',
            identifier: 'about',
          },
          {
            name: 'Contact',
            identifier: 'contact',
            url: '/contact',
          },
        ],
      },
    },
  },
};
export default menuConfig;
