import * as React from 'react';
import styles from './styles/index.module.scss';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location?: GatsbyLocation;
  testimonials: Array<Testimonial>;
  data?: any; // This would be the data provided by GraphQL queries if they were set up.
}

export interface Testimonial {
  author: string;
  quote: string;
}

const Testimonials: React.FunctionComponent<PageProps> = (props) => {
  return (
    <section className={styles.testimonials}>
      {props.testimonials.map<React.ReactElement>(
        (testimonial: Testimonial) => {
          return (
            <article key={testimonial.author}>
              <img
                className={styles.testimonialSeparator}
                src="/img/logo_icon.jpg"
                alt="logo icon"
              />
              <div className={styles.testimonialQuote}>{testimonial.quote}</div>
              <div className={styles.testimonialAuthor}>
                {testimonial.author}
              </div>
            </article>
          );
        }
      )}
      <article>
        <img
          className={styles.testimonialSeparator}
          src="/img/logo_icon.jpg"
          alt="logo icon"
        />
      </article>
    </section>
  );
};

export default Testimonials;
