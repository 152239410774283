import * as React from 'react';
import styles from './styles/index.module.scss';
// import { StateConsumer } from '../layout';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location?: GatsbyLocation;
  locale: {
    path: string;
    id: string;
    locale: string;
    default: boolean;
  };
  data?: any; // This would be the data provided by GraphQL queries if they were set up.
}

const Footer: React.FunctionComponent<PageProps> = (props) => {
  const translations = {
    de: 'Impressum',
    en: 'Legal Notice',
    re: 'отпечаток',
  };

  return (
    <footer className={[styles.footer].join(' ')}>
      {/* TODO: add multilangual impressum */}
      <a className={[styles.impressumLink].join(' ')} href="/impressum/">
        {translations[props.locale.path]}
      </a>
    </footer>
  );
};

export default Footer;
