import * as React from 'react';
import styles from './styles/index.module.scss';
import * as moment from 'moment';
// import * as ical2json from 'ical2json';
import { btnLink, btnLg } from '../Header/styles/index.module.scss';
import { Appointment } from '../Appointments/Appointments';
// import { convertVEventToAppointment } from '../../utils/ICalUtils';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  weekdays_short: Array<string>;
  months: Array<string>;
  appointments: any;
  location?: GatsbyLocation;
  data?: any;
  onDateSelect: (date: string | undefined) => void;
}

export interface CalendarDayItem {
  date: moment.Moment;
  classNames: Array<string>;
  button_classNames: Array<string>;
  tooltip: string | null;
}

const Calendar: React.FunctionComponent<PageProps> = (props) => {
  const [selectedMonth, setSelectedMonth] = React.useState(
    moment().startOf('month').format()
  );
  // fetch(
  //   'https://calendar.google.com/calendar/ical/771gq94dnnkngc8anv0oijucmg%40group.calendar.google.com/private-c0da24727e38afc93797da86bf087e88/basic.ics'
  // )
  //   .then((value) => value.text())
  //   .then((text_value) => {
  //     const json_calendar = ical2json.convert(text_value);
  //     const appointment = convertVEventToAppointment(
  //       json_calendar.VCALENDAR[0].VEVENT[0]
  //     );
  //     console.log(json_calendar.VCALENDAR[0].VEVENT[0], appointment);
  //   });

  const get_dates_array = (month = moment(selectedMonth)) => {
    let dates: Array<CalendarDayItem> = [];

    //#region Start Wochentagsberechnung https://de.wikipedia.org/wiki/Wochentagsberechnung
    let y: number, c: number;
    let m = ((moment(selectedMonth).month() + 10) % 12) + 1;
    if (m > 10) {
      y = (moment(selectedMonth).year() - 1) % 100;
      c = Math.floor((moment(selectedMonth).year() - 1) / 100);
    } else {
      y = moment(selectedMonth).year() % 100;
      c = Math.floor(moment(selectedMonth).year() / 100);
    }
    let firstdaycalculation =
      (1 +
        Math.floor(2.6 * m - 0.2) +
        y +
        Math.floor(y / 4) +
        Math.floor(c / 4) -
        2 * c +
        700) %
      7;
    // Ende Wochentagsbrechnung
    //#endregion

    let start_date = moment(month).subtract(firstdaycalculation, 'day');

    for (let i = 0; i < 42; i++) {
      let date: CalendarDayItem = {
        date: moment(start_date).add(i, 'day'),
        classNames: [styles.calendarDate],
        button_classNames: [styles.dateItem],
        tooltip: '',
      };

      if (date.date.isSame(moment(month).subtract(1, 'month'), 'month')) {
        date.classNames.push(styles.prevMonth);
      }

      if (date.date.isSame(moment(month).add(1, 'month'), 'month')) {
        date.classNames.push(styles.nextMonth);
      }

      if (date.date.isSame(moment(), 'day')) {
        date.button_classNames.push(styles.dateToday);
      }

      //#region Add appointments stuff
      const appointments_on_day: Array<Appointment> =
        props.appointments[date.date.format('YYYY-MM-DD')];

      if (appointments_on_day && appointments_on_day.length > 0) {
        date.tooltip = appointments_on_day
          .map((appointment) => appointment.type)
          .join('\n');
        date.classNames.push(styles.tooltip);
        date.button_classNames.push(styles.badge);

        switch (classifyAppointment(appointments_on_day[0])) {
          case 'meditation':
            date.button_classNames.push(styles.typeMeditation);
            break;
          case 'familienstellen':
            date.button_classNames.push(styles.typeFamilienstellen);
            break;
          case 'energetische_arbeit':
            date.button_classNames.push(styles.typeEnergetischeArbeit);

          default:
            break;
        }
      }
      //#endregion

      dates.push(date);
    }

    return dates;
  };

  const onCalendarItemClick = (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    props.onDateSelect(event.currentTarget.dataset.date);
  };
  const onNextMonth = () => {
    setSelectedMonth(moment(selectedMonth).add(1, 'month').format());
  };
  const onPreviousMonth = () => {
    setSelectedMonth(moment(selectedMonth).subtract(1, 'month').format());
  };

  const renderCalendarItem = (dateItem: CalendarDayItem) => {
    return (
      <div
        key={dateItem.date.format()}
        className={dateItem.classNames.join(' ')}
        data-tooltip={dateItem.tooltip}
      >
        <button
          className={dateItem.button_classNames.join(' ')}
          onClick={onCalendarItemClick}
          data-date={dateItem.date.format('YYYY-MM-DD')}
        >
          {dateItem.date.format('D')}
        </button>
      </div>
    );
  };

  const renderCalendarHeaderItem = (value: string) => {
    return (
      <div key={value} className={styles.calendarDate}>
        {value}
      </div>
    );
  };

  const renderCalendar = (
    heading: string,
    header_content: Array<JSX.Element>,
    body_content: Array<JSX.Element>
  ) => {
    return (
      <div className={styles.calendar}>
        <div className={[styles.calendarNav, styles.navbar].join(' ')}>
          <button
            onClick={onPreviousMonth}
            className={[styles.btn, styles.btnAction, btnLink, btnLg].join(' ')}
          >
            <i className={[styles.icon, styles.materialIcons].join(' ')}>
              keyboard_arrow_left
            </i>
          </button>
          <div className={[].join(' ')}>{heading}</div>
          <button
            onClick={onNextMonth}
            className={[styles.btn, styles.btnAction, btnLink, btnLg].join(' ')}
          >
            <i className={[styles.icon, styles.materialIcons].join(' ')}>
              keyboard_arrow_right
            </i>
          </button>
        </div>

        <div className={[].join(' ')}>
          <div className={[styles.calendarHeader].join(' ')}>
            {header_content}
          </div>
          <div className={[styles.calendarBody].join(' ')}>{body_content}</div>
        </div>
      </div>
    );
  };

  return (
    <article className={styles.calendarView}>
      {renderCalendar(
        props.months[parseInt(moment(selectedMonth).format('M')) - 1] +
          ' ' +
          moment(selectedMonth).format('YYYY'),
        props.weekdays_short.map((monthItem) => {
          return renderCalendarHeaderItem(monthItem);
        }),
        get_dates_array(moment(selectedMonth)).map((dateItem) => {
          return renderCalendarItem(dateItem);
        })
      )}
    </article>
  );
};

export default Calendar;

const classifyAppointment = (appointment: Appointment) => {
  if (appointment.type.toLowerCase().includes('meditation'))
    return 'meditation';

  if (appointment.type.toLowerCase().includes('familienstellen'))
    return 'familienstellen';

  if (appointment.type.toLowerCase().includes('energetische arbeit'))
    return 'energetische_arbeit';

  return '';
};
