import React from 'react';
import { Helmet } from 'react-helmet';
import { Header, Footer } from '.';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface LayoutProps {
  location: GatsbyLocation;
  locale: {
    path: 'de' | 'en' | 'ru';
    id: string;
    locale: string;
    default: boolean;
  };
  children: any;
  data?: any; // This would be the data provided by GraphQL queries if they were set up.
}

const Layout = (props: LayoutProps) => {
  return (
    <div className="layoutClass">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Kalam&display=swap"
          rel="stylesheet"
        />
        <script src="/js/moment.min.js"></script>
        <script src="/js/CalendarManager.js"></script>
        <link rel="stylesheet" href="/css/normalize.min.css" />
        {/* <link rel="stylesheet" href="/css/spectre.min.css" /> */}
        {/* <link rel="stylesheet" href="/css/spectre-exp.min.css" /> */}
        <title>Alternative Psychologie</title>
      </Helmet>
      <Header locale={props.locale} location={props.location} />
      {props.children}
      <Footer locale={props.locale} />
    </div>
  );
};

export default Layout;
