import * as React from 'react';
import styles from './styles/index.module.scss';
import menuConfig from './menuConfig';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location?: GatsbyLocation;
  locale: {
    path: 'de' | 'en' | 'ru';
    id: string;
    locale: string;
    default: boolean;
  };
  data?: any;
}

const Header: React.FunctionComponent<PageProps> = (props) => {
  const locale = props.locale.path || 'de';

  const renderSingleLanguage = (lang: string) => {
    return (
      <li
        key={'langimg_' + lang}
        className={[
          styles.language,
          lang === locale ? styles.languageActive : '',
        ].join(' ')}
      >
        <a
          href={
            props.location?.pathname.includes(props.locale.path)
              ? props.location?.pathname.replace(props.locale.path, lang)
              : '/' + lang + props.location?.pathname
          }
          lang={lang}
        >
          <img src={'/img/' + lang + '.svg'} alt={lang} />
        </a>
      </li>
    );
  };

  const renderMobileNav = () => {
    return (
      <div className={[styles.offCanvas].join(' ')}>
        <a
          className={[styles.offCanvasToggle, styles.btnAction].join(' ')}
          href="#menu_sidebar"
        >
          <img src="/img/menu.svg" alt="" />
        </a>

        <div id="menu_sidebar" className={[styles.offCanvasSidebar].join(' ')}>
          <nav className={[styles.headerNav].join(' ')}>
            {menuConfig.languages[props.locale.path].menu.main.map((item) => {
              return (
                <a
                  className={[
                    styles.headerNavItem,
                    props.location?.pathname.includes(item.url)
                      ? styles.active
                      : '',
                  ].join(' ')}
                  href={'/' + locale + item.url}
                  key={item.name}
                >
                  {item.name}
                </a>
              );
            })}
          </nav>

          <ul className={styles.languageSelection}>
            {Object.keys(menuConfig.languages).map((lang) => {
              return renderSingleLanguage(lang);
            })}
          </ul>

          <section className={[styles.headerLanguages].join(' ')}>
            <ul className={[styles.languageSelection].join(' ')}>
              {Object.keys(menuConfig.languages).map((lang) => {
                return renderSingleLanguage(lang);
              })}
            </ul>
          </section>
        </div>

        <a className={[styles.offCanvasOverlay].join(' ')} href="#close"></a>

        <div className={[styles.offCanvasContent].join(' ')}></div>
      </div>
    );
  };

  const renderDesktopNav = () => {
    return (
      <nav className={[styles.headerNav].join(' ')}>
        {menuConfig.languages[props.locale.path].menu.main.map((item) => {
          if (item.children) {
            return (
              <div
                key={item.identifier}
                className={[
                  styles.headerNavItem,
                  props.location?.pathname.includes(item.url)
                    ? styles.active
                    : '',
                ].join(' ')}
              >
                <a href={'/' + locale + item.url}>{item.name}</a>
                <ul className={[styles.headerNavItemDropdown].join(' ')}>
                  {item.children.map((itemChild) => {
                    return (
                      <li
                        key={itemChild.name}
                        className={[styles.headerNavItemDropdownItem].join(' ')}
                      >
                        <a href={'/' + locale + itemChild.url}>
                          {itemChild.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          } else {
            return (
              // TODO: add active flag
              <a
                key={item.identifier}
                className={[
                  styles.headerNavItem,
                  props.location?.pathname.includes(item.url)
                    ? styles.active
                    : '',
                ].join(' ')}
                href={'/' + locale + item.url}
              >
                {item.name}
              </a>
            );
          }
        })}
      </nav>
    );
  };

  const renderDesktopLanguages = () => {
    return (
      <section className={[styles.headerLanguages].join(' ')}>
        <ul className={[styles.languageSelection].join(' ')}>
          {Object.keys(menuConfig.languages).map((lang) => {
            return (
              <li
                key={'langimg_' + lang}
                className={[
                  styles.language,
                  lang === locale ? styles.languageActive : '',
                ].join(' ')}
              >
                <a
                  href={
                    props.location?.pathname.includes(props.locale.path)
                      ? props.location?.pathname.replace(
                          props.locale.path,
                          lang
                        )
                      : '/' + lang + props.location?.pathname
                  }
                  lang={lang}
                >
                  <img src={'/img/' + lang + '.svg'} alt={lang} />
                </a>
              </li>
            );
          })}
        </ul>
      </section>
    );
  };

  return (
    <header className={[styles.header].join(' ')}>
      {renderMobileNav()}
      <section className={[styles.headerLogo].join(' ')}>
        <a href={'/' + locale}>
          <img src="/img/logo.jpg" alt="Logo" />
        </a>
      </section>
      {renderDesktopNav()}
      {renderDesktopLanguages()}
    </header>
  );
};

export default Header;
