import * as React from 'react';
import styles from './styles/index.module.scss';
import * as moment from 'moment';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  no_appointments: string;
  another_day: string;
  appointments: Array<Appointment>;
  location?: GatsbyLocation;
  data?: any;
}

export interface Appointment {
  type: string;
  date: string;
  duration: number | string | undefined;
  location: string;
  image: string;
  number_of_days: number | string | undefined;
}

const Appointments: React.FunctionComponent<PageProps> = (props) => {
  const renderAppointments = () => {
    if (props.appointments == undefined) {
      return renderAppointmentEmpty();
    }

    return props.appointments.map((appointment) => {
      return (
        <section
          className={[
            styles.appointment,
            styles.textImageComponent,
            styles.textImageComponentCenter,
            styles.textImageComponentSmall,
          ].join(' ')}
        >
          <div
            className={styles.textImageComponentImage}
            style={{ backgroundImage: `url('${appointment.image}')` }}
          ></div>
          <div className={[styles.textImageComponentText].join(' ')}>
            <header className={[styles.appointmentType].join(' ')}>
              {appointment.type}
            </header>
            <div className={[styles.appointmentTime].join(' ')}>
              <i className={[styles.materialIcons].join(' ')}>access_time</i>
              <span>
                {moment(appointment.date).format('HH:mm')} -{' '}
                {moment(appointment.date)
                  .add(appointment.duration, 'hours')
                  .format('HH:mm')}
              </span>
            </div>
            <div className={[styles.appointmentPlace].join(' ')}>
              <i className={[styles.materialIcons].join(' ')}>location_on</i>
              <span>{appointment.location}</span>
            </div>
          </div>
        </section>
      );
    });
  };

  const renderAppointmentEmpty = () => {
    return (
      <div className={[styles.empty].join(' ')}>
        <h4 className={[styles.emptyTitle, styles.h5].join(' ')}>
          {props.no_appointments}
        </h4>
        <p className={[styles.emptySubtitle].join(' ')}>{props.another_day}</p>
      </div>
    );
  };

  return (
    <article id="appointment_view" className={styles.appointmentView}>
      {renderAppointments()}
    </article>
  );
};

export default Appointments;
